import React from 'react';
import Typed from 'react-typed';
import { PopupButton } from '@typeform/embed-react'

class Questions extends React.Component {
 constructor(props) {
    super(props);
    this.state = {
        visible:false,
        }
  }

render(){
    return (
    <section className='relative z-[1] py-48 w-[80%] mx-auto text-left'>
              <div className='z-[1000] pt-4 pb-1 w-full flex mb-6 gap-2 items-center border-opacity-20'>
                <svg
                className="mb-1 h-7 fill-white outline-1 outline-white inline-block"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 594 252"
                version="1.1"
                viewBox="0 0 594 252"
                xmlSpace="preserve">
                <path d="M240.8 103.3H236v43.3h4.5c12 0 22.2-6.6 22.2-21.6.1-13.9-8.9-21.7-21.9-21.7zM297 11.2L12 57.6v136.8l285 46.4 285-46.4V57.6L297 11.2zM97.8 163.6h-31V86.3h28.7c13.7 0 23 5.3 23 20.1 0 7-2.3 12.6-8.5 15.8v.2c11.1 1.4 15.8 8.9 15.8 19.7 0 16.2-13.9 21.5-28 21.5zm105.4-33.8c0 10-.4 19.8-8.1 27.3-6.5 6.4-16.6 8.6-25.6 8.6s-19.2-2.2-25.7-8.6c-7.7-7.5-8.1-17.3-8.1-27.3V86.3h20.1V127c0 10.1.7 21 13.6 21s13.6-10.9 13.6-21V86.3h20.1v43.5h.1zm41.2 33.8h-28.5V86.3h28.5c21.5 0 39.2 16.9 39.2 38.7 0 21.7-17.8 38.6-39.2 38.6zm120.7 0l-5.6-44.5h-.2l-18.6 44.5h-8L315 119.1h-.2l-6.5 44.5h-20l13.1-77.3h19.8l15.7 41.3 16.6-41.3h20l11.7 77.3h-20.1zm72.5-60.3h-23.9v12.9h22.7v17h-22.7v13.3h23.9v17h-44V86.2h44v17.1zm89.6 60.3h-20.1l-36.8-47.4h-.2v47.4H450V86.3h20.1l36.8 47.3h.2V86.3h20.1v77.3zM105.4 140c0 8.5-10.1 8.2-16 8.2h-2.5v-16.6h2c6.6 0 16.5-.9 16.5 8.4zm-16.3-23.3h-2.2v-15h1.4c5.4 0 11.7.4 11.7 7.5 0 6.5-5.5 7.5-10.9 7.5z"></path>
                </svg>
                <span className='uppercase tracking-widest text-white mb-1'>Design Lab</span>
            </div>
            <p className={this.state.visible?'fadeOut':'fadeIn lg:text-2xl text-xl mb-4'}>Over the years we have been asked...</p>
            <div className='text-white lg:text-5xl md:text-4xl text-3xl font-bold h-32'>
                <Typed
                    strings={[
                    'Can you 3D scan a 15ft statue and make a replica?',
                    'Can you make a cyborg sculpture?', 
                    // 'Can you 3D print a 200 year old ceiling ornament?',
                    // 'Can you teach a robot to paint for me?',
                    'Can you 3D print my brain?',
                    'Can you turn a Picasso painting into a sculpture?',
                    // 'Can you 3D scan and print a historic building?',
                    'Can you make a cloud that I can eat?',
                    'Do you have an impossible idea?']}
                    typeSpeed={30}
                    backSpeed={20}
                    backDelay={3000}
                    showCursor
                    loopCount={1}
                    cursorChar="|"
                    loop
                    onComplete={() => this.setState({ visible: true })} 
                    >
                </Typed>
            </div>
            <div className={this.state.visible?'fadeIn lg:text-2xl text-xl':'fadeOut'}>
                <p className='mb-6'>Our favorite challenges are the ones that leave people scratching their heads wondering... <span className='italic'>How did they do that?</span></p>
                {/* <p className='mb-6'>We don't take on outside projects often, but when we do, we do the impossible.</p> */}
                <p>So if you have a wildly challenging project, let's talk.</p>
                <PopupButton
                    href="/start"
                    id="FwrNI1WM"
                    className="pointer lg:text-xl text-lg mt-6 border-[#9333EA] border-2 bg-black hover:opacity-75 text-white font-bold py-2 px-4 rounded">
                    Get Started
                </PopupButton>
            </div>
    </section>
    );
}};

export default Questions;

import './App.css';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import Start from './pages/start';
import Questions from './pages/questions';


function App() {

  return (
    <div className="App bg-black bg-layers text-white">

      <article className='relative z-[1]'>
        <Router>
          <Routes>
            <Route path='/' element={<Questions />} />
            <Route exact path='/start' element={<Start />} />
          </Routes>
        </Router>

        <footer className="p-4 md:px-6 md:py-8 bg-transparent">
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

          <div className="sm:flex sm:items-center sm:justify-between">
            <a href="https://budmen.com/" className="flex items-center mb-4 sm:mb-0">
              <svg
                className="mx-3 mb-1 h-5 fill-white inline-block"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 594 252"
                version="1.1"
                viewBox="0 0 594 252"
                xmlSpace="preserve"
              >
                <path d="M240.8 103.3H236v43.3h4.5c12 0 22.2-6.6 22.2-21.6.1-13.9-8.9-21.7-21.9-21.7zM297 11.2L12 57.6v136.8l285 46.4 285-46.4V57.6L297 11.2zM97.8 163.6h-31V86.3h28.7c13.7 0 23 5.3 23 20.1 0 7-2.3 12.6-8.5 15.8v.2c11.1 1.4 15.8 8.9 15.8 19.7 0 16.2-13.9 21.5-28 21.5zm105.4-33.8c0 10-.4 19.8-8.1 27.3-6.5 6.4-16.6 8.6-25.6 8.6s-19.2-2.2-25.7-8.6c-7.7-7.5-8.1-17.3-8.1-27.3V86.3h20.1V127c0 10.1.7 21 13.6 21s13.6-10.9 13.6-21V86.3h20.1v43.5h.1zm41.2 33.8h-28.5V86.3h28.5c21.5 0 39.2 16.9 39.2 38.7 0 21.7-17.8 38.6-39.2 38.6zm120.7 0l-5.6-44.5h-.2l-18.6 44.5h-8L315 119.1h-.2l-6.5 44.5h-20l13.1-77.3h19.8l15.7 41.3 16.6-41.3h20l11.7 77.3h-20.1zm72.5-60.3h-23.9v12.9h22.7v17h-22.7v13.3h23.9v17h-44V86.2h44v17.1zm89.6 60.3h-20.1l-36.8-47.4h-.2v47.4H450V86.3h20.1l36.8 47.3h.2V86.3h20.1v77.3zM105.4 140c0 8.5-10.1 8.2-16 8.2h-2.5v-16.6h2c6.6 0 16.5-.9 16.5 8.4zm-16.3-23.3h-2.2v-15h1.4c5.4 0 11.7.4 11.7 7.5 0 6.5-5.5 7.5-10.9 7.5z"></path>
              </svg>
            </a>
            <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://budmen.com/" className="hover:underline">Budmen Industries ®</a> All Rights Reserved.
          </span>
            <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href="https://budmen.com/about" className="mr-4 hover:underline md:mr-6 ">About</a>
              </li>
              <li>
                <a href="https://budmen.com" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
              </li>
              <li>
                <a href="https://budmen.com/contact" className="hover:underline">Contact</a>
              </li>
            </ul>
          </div>

        </footer>
      </article>

      <div aria-hidden="true" className="fixed top-0 h-screen overflow-hidden inset-0 flex items-center">
        <div aria-hidden="true" className="bg-layers bg-scale w-56 h-56 m-auto blur-xl bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 rounded-full md:w-[30rem] md:h-[30rem] md:blur-3xl"></div>
      </div>
      <div aria-hidden="true" className="fixed overflow-hidden h-screen inset-0 w-full bg-[#020314] opacity-80"></div>
    </div>
  );
}

export default App;

import React from 'react';
import { Widget } from '@typeform/embed-react';


const Start = () => {

  return (
    <article className='relative z-[1]'>
      <section className="mt-8" id="start">
        <p className="text-3xl mb-6">Click start to answer a few quick questions about your project.</p>
        <Widget id="FwrNI1WM" data-tf-opacity="0" style={{ width: '100%', height: '500px'}} className="my-form" />
      </section>
    </article>
  );
};

export default Start;
